import React from 'react'
import styled from '@emotion/styled'
import { Button } from 'react-bulma-components'
import { css } from '@emotion/core'
import { DOWNLOAD } from '../Icons/icons'
import { WEGNOLOGY_RED } from '../Colors'

const AsideGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 275px;
  max-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-top: 20px;
  margin-left: auto;
  padding: 40px 40px;
  padding-right: 0;
  position: sticky;
  top: 64px;
  @media (max-width: 1100px) {
    display: none;
  }
`

const AsideHeader = styled.h4`
  font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  padding: 10px 20px;
  line-height: 1.1;
  font-weight: normal;
  background: #023867;
  margin-top: 0;
  margin-bottom: 0;
`

const AsideList = styled.ul`
  font-family: 'Alternate Gothic', 'Franklin Gothic Medium', 'Arial Narrow',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #111a2b;
  font-size: 130%;
  line-height: 1.1;
  padding-left: 0;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const ListItem = styled.li`
  align-items: center;
  list-style: none;
  font-size: 13px;
  position: relative;
  border-left: 0px solid transparent;
  font-family: 'fira sans', sans-serif;
  line-height: 14px;
`

const AsideLink = styled.a`
color: rgb(68, 68, 68) !important;
text-decoration: none !important;
padding: 10px 0px 10px 19px;
display: block;
border-radius: 4px;
transition: 0.3s;
font-weight: bold;
line-height: 18px;
background: ${(props) => props.backgroundColor}
&:focus {
  outline-width: 3px;
}
&:hover {
  background: white;
}
&:visited {
  color: rgb(68, 68, 68) !important;
}
`

const ListItems = ({ listItems, nested }) => {
  const depth = nested || 0

  const list = listItems.map(({ url, active, title, items }) => {
    const backgroundColor = active ? 'none' : '#e2e3e6'
    return (
      <ListItem key={url}>
        <AsideLink backgroundColor={backgroundColor} href={url}>
          {title}
        </AsideLink>
        {items && depth <= 0 && (
          <ul
            css={css`
              padding: 0;
              border-left: none;
              margin: 0px 0px 0px 15px;
            `}
          >
            <ListItems nested={depth + 1} listItems={items} />
          </ul>
        )}
      </ListItem>
    )
  })

  return list
}

const Aside = ({ post }) => {
  const { tableOfContents, frontmatter } = post
  const listItems = tableOfContents.items || []

  const showDownloadButton = !!frontmatter.downloadUrl

  const DownloadButton = (props) => {
    const { showDLButton } = props
    const downloadTemplate = () => {
      window.open(frontmatter.downloadUrl, '_blank')
    }
    if (showDLButton) {
      return (
        <>
          <Button
            onClick={downloadTemplate}
            id="run-all"
            css={css`
              background: ${WEGNOLOGY_RED};
              color: white;
              font-size: 18px;
              font-weight: bold;
              height: 40px;
              line-height: normal;
              font-family: 'Alternate Gothic', 'Franklin Gothic Medium',
                'Arial Narrow', sans-serif;
              text-align: center;
              white-space: nowrap;
              align-items: center;
              text-transform: uppercase;
              border: 1px solid transparent;
              border-radius: 4px;
              box-shadow: none;
              display: inline-flex;
              width: 100%;

              &:hover {
                color: white;
              }
            `}
          >
            <DOWNLOAD
              css={css`
                padding-right: 10px;
              `}
            />{' '}
            Download Template
          </Button>
          <a
            href="/applications/import-export/"
            target="_blank"
            css={css`
              font-size: 12px;
              font-family: 'Fira Sans', 'Lucida Grande', 'Trebuchet MS',
                sans-serif;
              color: #ff929d;
              display: flex;
              margin-top: 10px;
              margin-bottom: 15px;
              justify-content: center;
            `}
          >
            How to import?
          </a>
        </>
      )
    }
    return null
  }

  return (
    <AsideGroup>
      <AsideList
        css={css`
          max-width: 250px;
        `}
      >
        <DownloadButton showDLButton={showDownloadButton} />
      </AsideList>
      <div
        css={css`
          max-width: 250px;
        `}
      >
        <AsideHeader>On This Page</AsideHeader>
        <AsideList
          css={css`
            background: #f5f6f7;
          `}
        >
          <ListItems post={post} listItems={listItems} />
        </AsideList>
      </div>
    </AsideGroup>
  )
}

export default Aside
